import { TableCell, type SxProps } from '@mui/material'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import { useParams, type NavigateOptions } from '@tanstack/react-router'
import { memo } from 'react'
import { trpcReact } from '../../client'
import { formatDateTime, getUserLabel } from '../../lib/format'
import { usePermissions } from '../../lib/use-permissions'
import { TableCellHeader } from '../molecules/table-elements/table-cell-header'
import { TableRowLink } from '../molecules/table-elements/table-row-link'
import type { BackofficeUsage } from '../pages/backoffice/common/backoffice-contract-page/contract-page-content'
import { BackofficeTable } from './backoffice-table'
import { ChipOrganizations } from './chip-organisations'

type BackofficeUsersTableProps = {
  users: UserSummary[]
  type: BackofficeUsage
  sx?: SxProps
}

export function BackofficeUsersTable({ users, type, sx = {} }: BackofficeUsersTableProps): JSX.Element {
  return (
    <BackofficeTable
      headers={
        <>
          <TableCellHeader>Client</TableCellHeader>
          <TableCellHeader>Date de création</TableCellHeader>
          <TableCellHeader>Apporteur d’affaires</TableCellHeader>
        </>
      }
      rows={users.map((user) => (
        <UserRow key={user.id} user={user} type={type} />
      ))}
      sx={sx}
    />
  )
}

export type UserSummary = UserAccount & { creationTimestamp: number }

type UserRowProps = { user: UserSummary; type: BackofficeUsage }

function UserRow({ user }: UserRowProps) {
  const { type: currentUserType } = usePermissions()
  const { organization } = useParams({ strict: false })

  const url: NavigateOptions =
    currentUserType === 'platform'
      ? { to: '/bak/users/$userId', params: { userId: user.id } }
      : { to: '/partner/$organization/users/$userId', params: { organization: organization ?? '', userId: user.id } }

  return (
    <TableRowLink href={user.customerNumber ? url : undefined}>
      <TableCell>{getUserLabel(user)}</TableCell>
      <TableCell>{formatDateTime(user.creationTimestamp)}</TableCell>
      <TableCell>{user.customerNumber ? <ChipUserOrganizations userId={user.id} /> : <> </>}</TableCell>
    </TableRowLink>
  )
}

const ChipUserOrganizations = memo<{ userId: string }>(function ChipUserOrganizations({ userId }) {
  const { organization } = useParams({ strict: false })
  const { isLoading, data: organizations } = trpcReact.users.getUserOrganizations.useQuery(
    { userId: userId ?? '', requestingOrganization: organization ?? 'orus' },
    { enabled: !!userId },
  )

  if (organizations) return <ChipOrganizations isLoading={isLoading} organizations={organizations} />
  return undefined
})
